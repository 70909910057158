/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import './src/styles/global.css';
import CheckWebpFeature from "@components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

const windowGlobal = typeof window !== "undefined" && window
export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/properties\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/properties\/for-rent/)) {
      return false;
    }
  
    if (location.pathname.match(/properties-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/properties-map\/for-rent/)) {
      return false;
    }

    if (location.pathname.match(/commercial-properties\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/commercial-properties\/for-rent/)) {
      return false;
    }
  
    if (location.pathname.match(/commercial-properties-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/commercial-properties-map\/for-rent/)) {
      return false;
    }

    if (location.pathname.match(/off-plan-properties\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/off-plan-properties-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/off-plan-projects\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/properties\/short-term-for-rent/)) {
      return false;
    }
    
  
    return true;
};
export const onRouteUpdate = ({ location, prevLocation }) => {
    if(location.pathname === "/properties/short-term-for-rent/" || location.pathname === "/properties/short-term-for-rent"){
      window.location.replace("/properties/short-term-for-rent/in-dubai/");
    }
    if(location.pathname === "/properties-map/short-term-for-rent/" || location.pathname === "/properties-map/short-term-for-rent"){
      window.location.replace("/properties-map/short-term-for-rent/in-dubai//");
    }
}
// function initGTMOnEvent(event) {
//   initGTM();
//   event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
// }

// function initGTM() {
//   if (window.gtmDidInit) {
//     return false;
//   }

//   window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

//   var script = document.createElement('script');

//   script.type = 'text/javascript';
//   script.async = true;
//   script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-NHGVC6C`;

//   script.onload = function() {
//     dataLayer.push({
//       // ensure PageViews are always tracked
//       event: 'gtm.js',
//       'gtm.start': new Date().getTime(),
//       'gtm.uniqueEventId': 0,
//     });
//   };

//   document.head.appendChild(script);
// }

// export const onClientEntry = () => {
//     document.onreadystatechange = function() {
//       if (document.readyState !== 'loading') setTimeout(initGTM, 2000);
//     };

//     document.addEventListener('scroll', initGTMOnEvent);
//     document.addEventListener('mousemove', initGTMOnEvent);
//     document.addEventListener('touchstart', initGTMOnEvent);
// };